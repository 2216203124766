var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":this.$overlayVariant,"spinner-type":this.$overlayType,"rounded":this.$overlayRounded}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("สาขา: "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.branchId.$error,
                        },attrs:{"label":"nameTh","options":_vm.branchMaster,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.branchId),callback:function ($$v) {_vm.branchId=$$v},expression:"branchId"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.branchId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.branchId.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ประเภท: "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.payMethod.$error,
                        },attrs:{"label":"type","options":_vm.payMethCode,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.payMethod),callback:function ($$v) {_vm.payMethod=$$v},expression:"payMethod"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.payMethod.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.payMethod.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-7 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3"},[_vm._v(" รหัสการรับชำระ: "),_c('b-form-input',{staticClass:"form-control",model:{value:(_vm.payMethod.payName),callback:function ($$v) {_vm.$set(_vm.payMethod, "payName", $$v)},expression:"payMethod.payName"}})],1)]),_c('div',{staticClass:"col-12 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ชื่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameTh.$error,
                        },model:{value:(_vm.nameTh),callback:function ($$v) {_vm.nameTh=$$v},expression:"nameTh"}}),(_vm.submitform && _vm.$v.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3"},[_vm._v(" ชื่อ (อังกฤษ): "),_c('b-form-input',{staticClass:"form-control",model:{value:(_vm.nameEn),callback:function ($$v) {_vm.nameEn=$$v},expression:"nameEn"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"success"},on:{"click":_vm.tooltipForm}},[_vm._v(" บันทึก ")])],1)])])])])])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }